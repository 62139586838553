import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  CdnUploadImage,
  CdnUploadImageValidation,
} from './CdnUploadImageExtension';

export const CdnUploadImageFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'CdnUploadImage',
    component: CdnUploadImage,
    validation: CdnUploadImageValidation,
  }),
);