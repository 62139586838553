import React, { useState } from 'react';
import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import { FormHelperText, Input, InputLabel, CircularProgress, Box, Select, MenuItem } from '@material-ui/core';
import FormData from 'form-data';
import axios from 'axios';
import { useApi, configApiRef } from '@backstage/core-plugin-api';

export const CdnUploadImage = ({
    onChange,
    rawErrors,
    required,
    formData,
}: FieldExtensionComponentProps<string>) => {
    const [fileName, setFileName] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [cdnEnv, setCdnEnv] = useState<string>('')
    const configApi = useApi(configApiRef);

    const { data }: any = configApi.getConfig("app.cdn.environments")

    const opt: any = {}

    Object.values(data).forEach((value: any, index: any) => {
        opt[`option${index}`] = value.title;
    })

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];

            const data = new FormData();
            data.append('file', file, file.name);

            console.log(configApi.get())
            const config = {
                method: 'post',
                maxContentLength: Infinity,
                maxBodyLength: Infinity,
                url: configApi.getString(`app.cdn.environments.${cdnEnv}.url`),
                data: data,
            };
            onChange("")
            setIsLoading(true)
            const response = await axios.request(config);
            console.log(response.data.id);
            setFileName(file.name);
            onChange(response.data.id)
            setIsLoading(false)
        }
    };
    return (
        <>
            <FormControl
                margin="normal"
                required={required}
                error={rawErrors?.length > 0 && !formData}
            >
                <InputLabel htmlFor="env">CDN Environment</InputLabel>
                <Select
                    id="env"
                    onChange={(e: any) => setCdnEnv(e.target.value)}
                >
                    {Object.values(opt).map((option: any) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl
                margin="normal"
                required={required}
                error={rawErrors?.length > 0 && !formData}
                disabled={!(cdnEnv.length > 0)}
            >
                <InputLabel htmlFor="fileUpload">Upload File</InputLabel>
                <Input
                    id="fileUpload"
                    type="file"
                    onChange={handleFileChange}
                />
                {fileName && (
                    <FormHelperText id="fileName">
                        Uploaded file: {fileName}
                    </FormHelperText>
                )}
                {isLoading && (
                    <Box sx={{ display: 'flex', marginTop: '30px' }}>
                        <CircularProgress />
                    </Box>
                )}
            </FormControl>
        </>
    );
};

/*
 This is a validation function that will run when the form is submitted.
  You will get the value from the `onChange` handler before as the value here to make sure that the types are aligned\
*/

export const CdnUploadImageValidation = (
    value: string,
    validation: FieldValidation,
) => {
    if (value === "") {
        validation.addError(
            `Image is not uploaded yet`,
        );
    }
};
