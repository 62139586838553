import React from 'react';
import { makeStyles } from '@material-ui/core';

import DigandoLogoFull from './digando-logo.svg';

const useStyles = makeStyles({
  logo: {
    width: "350%"
  }
});
const LogoFull = () => {
  const classes = useStyles();

  return <img className={classes.logo}src={DigandoLogoFull} />

};

export default LogoFull;
